@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700&display=swap');

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-end-rgb: 0, 0, 0;
  }
}

html,
body {
  font-family: 'Nunito Sans', sans-serif;
}

@keyframes pingEffect {
  0% {
    transform: scale(0.8);
    opacity: 1;
  }

  100% {
    transform: scale(2.0);
    opacity: 0;
  }
}

.custom-gradient {
  background: radial-gradient(circle at top right, rgba(255, 102, 102, 1) 0%, rgba(255, 102, 102, 0) 80px, transparent 20px), #FFF5F1;
}

.star {
  width: 70px;
  height: 70px;
  background-color: #ffd700;
  /* Gold color for the star */
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  animation: moveStar 5s linear infinite;
  filter: drop-shadow(0 0 10px #000);
}

@keyframes moveStar {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(20px);
  }
}

@keyframes circular-motion {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.flip-on-hover {
  perspective: 1000px;
  /* Add perspective for a more 3D effect */
  transition: transform 0.6s ease-in-out;
}

.flip-on-hover:hover {
  transform: rotateY(180deg);
}

.flip-on-hover-text {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.6s ease-in-out;
}

.flip-on-hover:hover .flip-on-hover-text {
  transform: rotateY(-180deg);
  /* Rotate text in the opposite direction */
}

@keyframes customPulse {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}

.pulse-img {
  animation: customPulse 2s infinite ease-in-out;
}

@keyframes spin-and-pause {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


@keyframes blink {
  50% {
    opacity: 0;
  }
}

.typing::after {
  content: '|';
  animation: blink 1s step-end infinite;
}

@keyframes HeaderImgPulse {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.8);
  }
}

.pulse-HeaderImg {
  animation: HeaderImgPulse 8s infinite ease-in-out;
}

@keyframes moveRightToLeft {

  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-100px);
    /* Negative value for moving to the left */
  }
}

.moveRightToLeft {
  animation: moveRightToLeft 7s infinite ease-in-out;
}

@keyframes moveSlowlyRightTop {

  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(30px, -30px);
    /* Adjust these values as needed */
  }
}

.moveSlowlyRightTop {
  animation: moveSlowlyRightTop 5s infinite ease-in-out;
}

@keyframes customcircularMotion {
  0% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(50px, 50px);
  }

  50% {
    transform: translate(0, 100px);
  }

  75% {
    transform: translate(-50px, 50px);
  }

  100% {
    transform: translate(0, 0);
  }
}

.custom-circularMotion {
  animation: customcircularMotion 10s infinite linear;
}

@keyframes floatBottomToTop {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-30px);
  }
}

.floatBottomToTop {
  animation: floatBottomToTop 5s infinite ease-in-out;
}

@keyframes abtBnrfloat {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-20px);
  }

  100% {
    transform: translatey(0px);
  }
}

.abtStar {
  animation: abtBnrfloat 6s ease-in-out infinite;
  position: absolute;
  color: gold;
}

.no-scrollbar::-webkit-scrollbar {
  width: 0;
  /* Set the width of the scrollbar to 0 */
  display: none;
  /* Hide the scrollbar on Chrome and Safari */
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
